import EditBudgetColumn from './EditBudget'

type CostColumnProps = {
  isListAll: boolean
  campaignAdType?: CampaignAdType
}
type CostColumn = (data: CostColumnProps) => SchemaItems

export const cost: CostColumn = ({ isListAll, campaignAdType }) => {
  const label = {
    product: {
      label: 'CPC',
      helpText:
        'Custo por clique - valor cobrado por cada clique em um anúncio.'
    },
    banner: {
      label: 'CPM',
      helpText: 'Custo por milhar - valor cobrado por cada mil impressões.'
    }
  } as { [key in CampaignAdType]: { label: string; helpText?: string } }

  if (campaignAdType === 'sponsored_brand')
    return {
      CPC: {
        title: 'CPC',
        helpText: label.product.helpText,
        render: data => <EditBudgetColumn {...data} ad_type="product" />
      },
      CPM: {
        title: 'CPM',
        helpText: label.banner.helpText,
        render: data => <EditBudgetColumn {...data} ad_type="banner" />
      }
    }

  return {
    cost: {
      title: isListAll
        ? 'CPC/CPM'
        : label?.[campaignAdType]?.label || 'CPC/CPM',
      helpText: label?.[campaignAdType]?.helpText,
      render: data =>
        data.ad_type === 'sponsored_brand' ? (
          <>
            <EditBudgetColumn {...data} ad_type="product" />
            <EditBudgetColumn {...data} ad_type="banner" />
          </>
        ) : (
          <EditBudgetColumn {...data} />
        )
    }
  }
}
